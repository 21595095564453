import { Link } from 'gatsby'
import React from 'react'

import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'

const CookiesPage = ({ location }) => (
  <Layout location={location}>
    <PageContent>
      <Header />
      <SEO title='Cookies Policy' />
      <div className='plain-text'>
        <div className='heading'>Cookies Policy</div>
        <div className='divider' />
        <p>Last updated March 11, 2021</p>
        <p>
          Welcome to TheBiz.pro (<strong>“Site”</strong>). The Site is owned and
          operated by Synonym Software Ltd. (<strong>“Synonym”</strong>,{' '}
          <strong>“we,”</strong> <strong>“our,”</strong> or{' '}
          <strong>“us”</strong>).
        </p>
        <p>
          We place cookies and other tracking technologies (such as pixels,
          tags, beacons, scripts, social media plugins, device fingerprinting,
          etc.) on your devices (together <strong>“cookies”</strong>). These
          cookies may be deployed by us but also by other parties. This cookies’
          policy (the <strong>“Cookies Policy”</strong>) explains how cookies
          are used on the Site and how you can control them. Cookies may also
          collect Personal Information (such as your IP address). Take a look at
          our <Link to='/privacy'>Privacy Statement</Link> to know how we
          process Personal Information.
        </p>
        <p>
          Capitalized terms that are not defined in this Cookies Policy shall
          have the meaning ascribed in our
          <Link to='/terms'>Terms of Service</Link> or in our Privacy Statement.
        </p>

        <h3>1. What are Cookies?</h3>
        <p>
          “Cookies” are small text files placed on your device/terminal. Cookies
          are used to collect information about how you use websites or
          application, such as information about your computer or device, web
          browser and operating system and their settings, the pages and content
          you see during your visit and when and for how long you do so, etc.
        </p>
        <p>
          Cookies can be distinguished in &quot;session cookies&quot; and
          &quot;persistent cookies&quot; depending on the time they remain
          stored on your device:
        </p>
        <ul>
          <li>
            a) “Session cookies” are stored on your device the moment you visit
            the Site/application and are automatically deleted when you close
            your browser/application.
          </li>
          <li>
            b) “Persistent cookies” are stored on your device when you visit the
            Site/application and remain stored after you close your browser.
            They will automatically be activated again when you open your
            browser/application. They will remain on your device until their
            expiration date or until you delete them.
          </li>
        </ul>
        <p>
          Cookies can be also distinguished in “first party cookies” and “third
          party cookies” depending on the website or the domain that installs
          the cookies.
        </p>
        <ul>
          <li>
            a) “First party cookies” are installed by the domain you visit, in
            our case TheBiz.pro.
          </li>
          <li>
            b) “Third party cookies” are installed by a different domain than
            the one you visit. If you visit a website and a third company
            installs a cookie through this website, this will be a third party
            cookie.
          </li>
        </ul>

        <h3>2. What Types of Cookies We Use and Why?</h3>
        <p>
          The cookies on the Site may be from any of the following categories:
          necessary, functional, analytics, and advertising cookies. The Site
          may also embed social media plugins. You can find more details below.
        </p>
        <ul>
          <li>
            a) Strictly Necessary cookies – These are used for technical reasons
            and are essential to enable the Site to operate efficiently so that
            you can navigate it with ease. These include, for example, cookies
            that help us to debug errors.
          </li>
          <li>
            b) Functional cookies - These are used to improve the functionality
            of the Site and make it easier to use. They help us to identify you
            as a repeat user of the Site and help us remember your preferences
            (for example, your choice of language or region)
          </li>
          <li>
            c) Analytical – These are used to measure how users interact with
            Site content. Analytics cookies allow us to record how many people
            visits our Site, how they navigate within our Site, and what content
            is popular.
          </li>
          <li>
            d) Advertising – These are used to understand what is of interest to
            you and display relevant advertising.
          </li>
          <li>
            e) Social media plugins, such as Linked-in, YouTube, and Twitter
            sharing buttons embedded in our Site may install cookies of the
            social media on your device. These plug-ins allow you to share
            content of our website on the social media but can be also used to
            create user profiles and track you across channels.
          </li>
        </ul>

        <h3>3. How can I set up my Cookies’ Preferences?</h3>
        <p>The Site uses strictly necessary cookies by default.</p>
        <p>
          Most internet browsers offer means to control the use of cookies,
          which allow you to block or delete cookies at any time. You may change
          the settings of your browser, so that it informs you prior to a cookie
          being stored on your device or follow instructions to delete all
          cookies which have been stored on your device. If you use multiple
          devices, you must change your browser settings in all of them. Note
          that if you refuse to accept cookies, you may not be able to use some
          of the features available on the Site.
        </p>
        <p>
          To manage your cookies preferences through your browser, follow these
          links, depending on the browser you are using:
        </p>
        <ul className='no-padding'>
          <li>
            <a
              className='color-text'
              href='https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies'
              target='_blank'
              rel='noreferrer'
            >
              Microsoft Windows Explorer
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'
              target='_blank'
              rel='noreferrer'
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en'
              target='_blank'
              rel='noreferrer'
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://support.apple.com/kb/ph19214?locale=en_US'
              target='_blank'
              rel='noreferrer'
            >
              Apple Safari
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://www.opera.com/help'
              target='_blank'
              rel='noreferrer'
            >
              Opera
            </a>
          </li>
        </ul>

        <p>On mobile devices:</p>
        <ul className='no-padding'>
          <li>
            <a
              className='color-text'
              href='https://support.google.com/android/?hl=en#topic=7313011'
              target='_blank'
              rel='noreferrer'
            >
              Android
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://support.apple.com/en-us/HT201265'
              target='_blank'
              rel='noreferrer'
            >
              Safari
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://support.microsoft.com/en-us/products/windowsphone'
              target='_blank'
              rel='noreferrer'
            >
              Windows Phone
            </a>
          </li>
          <li>
            <a
              className='color-text'
              href='https://global.blackberry.com/en/support'
              target='_blank'
              rel='noreferrer'
            >
              Blackberry
            </a>
          </li>
        </ul>
        <p>
          If you use another browser, you may find the cookie management
          instructions in the support page of your browser.
        </p>
        <p>
          You can also take proactive steps yourself and opt out of being
          tracked by Google Analytics across all websites, visit
          http://tools.google.com/dlpage/gaoptout for more information.
        </p>
        <p>
          You can learn more on cookies on the following third-party websites:
        </p>
        <ul>
          <li>
            a) AllAboutCookies:{' '}
            <a
              href='https://www.allaboutcookies.org/'
              target='_blank'
              rel='noreferrer'
            >
              https://www.allaboutcookies.org/
            </a>
          </li>
          <li>
            b) Cookiepedia:{' '}
            <a
              href='https://cookiepedia.co.uk/'
              target='_blank'
              rel='noreferrer'
            >
              https://cookiepedia.co.uk/
            </a>
          </li>
        </ul>

        <h3>4. Contact</h3>
        <p>
          If you have any queries about the way we use cookies, please contact{' '}
          <a href='mailto:privacy@synonym.to'>privacy@synonym.to</a>.
        </p>
      </div>
      <FooterMenu className='page-content__footer' />
    </PageContent>
  </Layout>
)

export default CookiesPage
